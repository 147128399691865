import React from 'react'
import './index.css'
import './navigation.css'
import './btn.css'
import 'font-awesome/css/font-awesome.min.css'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ({ head, children }) => (
  <StaticQuery
    render={data => {
      const siteTitle =
        get(head, 'title') || get(data, 'site.siteMetadata.title')
      const siteName = get(head, 'name') || get(data, 'site.siteMetadata.name')
      const siteMetaDescription =
        get(head, 'meta.description') ||
        get(data, 'site.siteMetadata.meta.description')
      const siteMetaKeywords =
        get(head, 'meta.keywords') ||
        get(data, 'site.siteMetadata.meta.keywords')
      const siteBanner = get(head, 'meta.banner')
      const siteMetaGoogle =
        get(head, 'meta.google') || get(data, 'site.siteMetadata.meta.google')

      return (
        <>
          <Helmet
            title={siteTitle + ' - ' + siteName}
            meta={[
              {
                name: 'description',
                content: siteMetaDescription,
              },
              {
                name: 'keywords',
                content: siteMetaKeywords,
              },
              {
                name: 'google-site-verification',
                content: siteMetaGoogle,
              },
              {
                property: 'og:image',
                content: siteBanner,
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header siteTitle={siteName} />
          <div>{children}</div>
          <Footer />
        </>
      )
    }}
    query={graphql`
      query siteQuery {
        site {
          siteMetadata {
            title
            name
            meta {
              keywords
              description
              google
            }
          }
        }
      }
    `}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
