import React from 'react'
import PG from '../../images/progrowth-and-xceleration-grey.png'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class Footer extends React.Component {
  state = {
    FNAME: null,
    LNAME: null,
    email: null,
  }

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    })
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    console.log('submit', this.state)
    addToMailchimp(this.state.email, {
      FNAME: this.state.FNAME,
      LNAME: this.state.LNAME,
    })
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)
        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log('err', err)
        alert(err)
      })
  }
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-4">
              <a href="/" className="logo">
                <img src={PG} alt="ProGrowth" className="img-fluid" />
              </a>
              <p>
                Joe Dauskurdas&nbsp;is the founder of ProGrowth Advisors and is
                a Certified Sales Leader focused on helping businesses achieve
                sales excellence. ProGrowth leverages the Sales Xceleration
                Platform to help clients exceed their growth and sales targets
                across a range of industries by building successful sales plans
                and highly productive sales teams.
              </p>
            </div>
            <div className="col-md-3 offset-md-1">
              <div>
                <h6>Connect with Us</h6>
                <ul>
                  <li>Austin, TX</li>
                  <li>
                    <a
                      href="mailto:joe@ProGrowthAdvisors.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      joe@ProGrowthAdvisors.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="tel:512-696-1333"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      512-696-1333
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-social">
                <h6>We're Social</h6>
                <a
                  href="https://www.linkedin.com/in/joedauskurdas/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin-square" />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <h6>Subcribe Our Newsletter</h6>

              <form className="text-center" onSubmit={this._handleSubmit}>
                <div className="form-group mb-0">
                  <label className="d-none">First Name</label>
                  <input
                    type="text"
                    onChange={this._handleChange}
                    className="form-control border-radius-0 mb-20"
                    placeholder="First Name"
                    name="FNAME"
                    id="mce-FNAME"
                  />
                </div>
                <div className="form-group mb-0">
                  <label className="d-none">Last Name</label>
                  <input
                    type="text"
                    onChange={this._handleChange}
                    className="form-control border-radius-0 mb-20"
                    placeholder="Last Name"
                    name="LNAME"
                    id="mce-LNAME"
                  />
                </div>
                <div className="form-group mb-0">
                  <label className="d-none">Email</label>
                  <input
                    type="email"
                    onChange={this._handleChange}
                    className="form-control border-radius-0 mb-20"
                    placeholder="Email"
                    name="email"
                  />
                </div>

                <button type="submit" className="red-btn">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
          <div className="copyright">
            <span>
              © {new Date().getFullYear()} ProGrowth Advisors. All Rights
              Reserved.
              <br />
              Designed and Powered by{' '}
              <a
                href="http://webriq.com"
                target="_blank"
                alt="WebriQ"
                rel="noopener noreferrer"
              >
                WebriQ
              </a>
              .<br />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
