import React from 'react'
import { Link } from 'gatsby'
import Sidebar from 'react-sidebar'
import style from './index.module.css'
import Bars from '../../images/bars.svg'
import Tel from '../../images/telephone.png'
import Logo from '../../images/progrowth-and-xceleration.png'
import Close from '../../images/close-envelope.png'

class Header extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sidebarOpen: false,
		}
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
	}

	onSetSidebarOpen(open) {
		this.setState({ sidebarOpen: open })
	}

	render() {
		return (
			<Sidebar
				sidebarClassName={style.sidebar}
				pullRight={true}
				rootClassName="sidebar-show"
				sidebar={
					<div className="sidebar-list">
						<ul className="list-unstyled main-nav text-left">
							<li>
								<a href="/">
									<img src={Logo} alt="logo2" />
								</a>
							</li>
							<li>
								<a href="/">Home</a>
							</li>
							<li>
								<Link to="/about">About Us</Link>
							</li>
							<li>
								<Link to="/services">How We Help</Link>
							</li>
							<li>
								<Link to="/testimonials">Testimonials</Link>
							</li>
							<li>
								<Link to="/videos">Videos</Link>
							</li>
							<li>
								<Link to="/blog">Blog</Link>
							</li>
							<li>
								<a href="/contact-us">Contact Us</a>
							</li>
							<li className="nav-contact-links">
								<span className="header-span">Talk to an Advisor</span>
								<a
									href="tel:512-696-1333"
									className="header-contact-link"
									target="_blank"
									rel="noopener noreferrer"
								>
									512-696-1333
								</a>
							</li>
							<li className="nav-contact-links">
								<span className="header-span">Drop Us a Line</span>
								<a
									href="mailto:joe@ProGrowthAdvisors.com"
									className="header-contact-link"
									target="_blank"
									rel="noopener noreferrer"
								>
									joe@ProGrowthAdvisors.com
								</a>
							</li>
						</ul>
					</div>
				}
				open={this.state.sidebarOpen}
				onSetOpen={this.onSetSidebarOpen}
				styles={{ sidebar: { background: '#fff', float: 'right' } }}
			>
				<div id="main-nav" className="navigation animated">
					<div className="container">
						<div className="row">
							<div className="col-lg-5 col-md-6 align-self-center col-sm-8 col-8">
								<a href="/" className="logo">
									<img src={Logo} alt="Logo" className="img-fluid" />
								</a>
							</div>
							<div className="col-lg-7 col-md-6 text-right align-self-center col-sm-4 col-4">
								<div className="header-contacts">
									<div className="header-contact">
										<img src={Tel} alt="telephone" />
										<div>
											<span className="header-span">Talk to an Advisor</span>
											<a
												href="tel:512-696-1333"
												className="header-contact-link"
												target="_blank"
												rel="noopener noreferrer"
											>
												512-696-1333
											</a>
										</div>
									</div>
									<div className="header-contact">
										<img src={Close} alt="close" />
										<div>
											<span className="header-span">Drop Us a Line</span>
											<a
												className="header-contact-link"
												href="mailto:joe@ProGrowthAdvisors.com"
												target="_blank"
												rel="noopener noreferrer"
											>
												joe@ProGrowthAdvisors.com
											</a>
										</div>
									</div>
								</div>
								<div className="navigation-menu">
									<ul>
										<li className="list-inline-item nav_content">
											<button
												className="border-out-0"
												onClick={() => this.onSetSidebarOpen(true)}
											>
												<img src={Bars} alt="bars" />
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Sidebar>
		)
	}
}

export default Header
